:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  // breakpoints custom properties
  @each $bp, $value in $grid-breakpoints {
    --#{$variable-prefix}breakpoint-#{$bp}: #{$value};
  }

  // mobile breakpoint custom property
  --#{$variable-prefix}mobile-breakpoint: #{$mobile-breakpoint};

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --#{$variable-prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
  --#{$variable-prefix}font-monospace: #{inspect($font-family-monospace)};
  --#{$variable-prefix}gradient: #{$gradient};
  --#{$variable-prefix}body-color: #{$body-color};
}
